import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';


function setVoltageIC(voltage) {
    console.log("Set voltage to " + voltage);

    // Sending this voltage to the IC using a post request
    // to the server as headers
    fetch(`http://${process.env.REACT_APP_URL}/${voltage}`, {
        method: 'POST',
        headers: {
            'volt': voltage,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ voltage: voltage }),
    })
        .then((response) => response.json())
        .then((data) => {
            window.alert("Voltage set to " + voltage);
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}


const card = (
    <React.Fragment>
        <CardContent>
            <Typography variant="h4" component="div">
                Set Voltage
            </Typography>
            <Typography color="text.secondary">
                Please select the voltage you would like to set the device to.
            </Typography>
        </CardContent>
        <Stack spacing={2} sx={{ m: 2.0 }} direction="row">
            <Button variant="contained" onClick={
                () => setVoltageIC(1.5)
            }>1.5</Button>
            <Button variant="contained" onClick={
                () => setVoltageIC(2.6)
            }>2.6</Button>
            <Button variant="contained" onClick={
                () => setVoltageIC(2.95)
            }>2.95</Button>
            <Button variant="contained" onClick={
                () => setVoltageIC(4.05)
            }>4.05</Button>
        </Stack>
    </React.Fragment>
);

export default function SetVoltage() {
    return (
        <Box sx={{ minWidth: 275 }}>
            <Card sx={{ m: 4.0 }} variant="border">{card}</Card>
        </Box>
    );
}