import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const card = (
    <React.Fragment>
        <CardContent>
            <Typography variant="h4" component="div">
                Present Voltage
            </Typography>
            <Typography color="text.secondary">
                The present voltage in the system
            </Typography>
        </CardContent>
        <Stack spacing={2} sx={{ m: 2.0 }} direction="row">
            <Button variant="outlined">1.5</Button>
        </Stack>
    </React.Fragment>
);

export default function PresentVoltage() {
    return (
        <Box sx={{ minWidth: 275 }}>
            <Card sx={{ m: 4.0 }} variant="border">{card}</Card>
        </Box>
    );
}