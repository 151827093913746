import './App.css';
import ResponsiveAppBar from './components/navbar';
import PresentVoltage from './components/presentVoltage';
import SetVoltage from './components/setVoltage';

function App() {
  return (
    <>
      <ResponsiveAppBar />
      <SetVoltage />
      <PresentVoltage />
    </>
  );
}

export default App;
